import React, {useState, useRef} from 'react';
import BlockContent from "@sanity/block-content-to-react";
import styled from 'styled-components';
import breakpoints from '../styles/breakpoints';
import Icon from './Core/Icon';
import CopyInColumns from './CopyInColumns';

const ContentWrapper = styled.div`
  height: ${props => props.showMobileAccordion ? "12rem" : "auto"};
  width: 100%;
  overflow: hidden;
  position: relative;
  @media(min-width: ${breakpoints.md}) {
    height: ${props => props.showDesktopAccordion ? "12rem" : "auto"};
  }
  svg {
    fill: ${props => props.copyColor ? props.copyColor : ""};
  }
`;

const BlockWrapper = styled.div`
  max-height: ${props => props.showMobileAccordion ? "9rem" : "none"};
  overflow: hidden;
  position: relative;
  &:after{
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    height: 4.5rem;
    background: ${props => props.backgroundColor ? `linear-gradient(0deg, ${props.backgroundColor} 0%, transparent 100%)` : "linear-gradient(0deg, rgb(239, 239, 239) 0%, transparent 100%)"};
    opacity: 1;
    display: ${props => props.showMobileAccordion ? "block" : "none"};
  @media only screen and (min-width: ${breakpoints.md}) {
    display: ${props => props.showDesktopAccordion ? "block": "none"};
  }
  }
  @media only screen and (min-width: ${breakpoints.md}) {
    max-height: ${props => props.showDesktopAccordion ? "8.5rem" : "none"};
    }
`;

const ReadMore = styled.div`
    position: absolute;
    bottom: 1.5rem;
    cursor: pointer;
    display: ${props => props.showMobileAccordion ? "block" : "none"};
    @media(min-width: ${breakpoints.md}) {
      display: ${props => props.showDesktopAccordion ? "block": "none"};
    }
    svg {
      width: 2rem;
      height: 2rem;
      position: absolute;
      bottom: -0.375rem;
      left: 5.75rem;
    }
`;

const ReadLess = styled.div`
    cursor: pointer;
    display: block;
    position: relative;
    margin-top: 2.5rem;
    margin-bottom: 1.25rem;
    svg {
      width: 2rem;
      height: 2rem;
      position: absolute;
      bottom: -0.45rem;
      left: 5.125rem;
      transform: rotate(180deg);
    }
`;

const BlockContentWithAccordion = ({_rawCopy, showBothAccordion, showMobileAccordion, showDesktopAccordion, serializers, hasImage, backgroundColor, copyColor}) => {
    const [mobileAccordionVisible, setMobileAccordionVisible] = useState(showBothAccordion || showMobileAccordion);
    const [desktopAccordionVisible, setDesktopAccordionVisible] = useState((showBothAccordion || showDesktopAccordion) && !hasImage);
    const [showReadLess, setShowReadLess] = useState(false)

    const copyRef = useRef(null);

    const onClickAccordion = () => {
        setMobileAccordionVisible(!mobileAccordionVisible)
        setDesktopAccordionVisible(!desktopAccordionVisible)
        setShowReadLess(true)
      }

    const onClickReadLess = () => {
        if (copyRef.current) {
          copyRef.current.scrollIntoView({
            behavior: 'smooth'})
        }

        setMobileAccordionVisible(true);
        setDesktopAccordionVisible(true);
        setShowReadLess(false);
    }

    return (
        <ContentWrapper ref={copyRef} showMobileAccordion={mobileAccordionVisible} showDesktopAccordion={desktopAccordionVisible} copyColor={copyColor?.hex}>
            <BlockWrapper showMobileAccordion={mobileAccordionVisible} showDesktopAccordion={desktopAccordionVisible} backgroundColor={backgroundColor?.hex}>
                <BlockContent blocks={_rawCopy} serializers={serializers}/>
                {showReadLess && <ReadLess onClick={onClickReadLess}>
                        Read less
                        <Icon name="icon-arrow" /></ReadLess>}
            </BlockWrapper>
            <ReadMore onClick={onClickAccordion} showMobileAccordion={mobileAccordionVisible} showDesktopAccordion={desktopAccordionVisible} >
                Read more
                <Icon name="icon-arrow" />
            </ReadMore>
      </ContentWrapper>
    );
};

export default BlockContentWithAccordion;